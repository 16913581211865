.button-conn-container {
  display: grid;
  grid-template-columns: 70% 30%;
  cursor: pointer;
}

.connect-button-svg-container {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 2rem;
}

.connect-silhouet-svg-container {
  padding-top: 0.5rem;
  display: grid;
  align-items: end;
}
