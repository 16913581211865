.score-container {
    grid-area: scores-card;
    display: grid;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 600px;
    justify-self: center;
    align-self: center;
    grid-template-rows: min-content;
    background-color: white;
    border-radius: 2px;
    box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, .5);
    padding: 20px;
    align-items: start;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

    .title-row {
        border-bottom: 2px solid gainsboro !important;
        text-transform: uppercase;
        font-weight: bold;
    }

    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid gainsboro;
        margin-bottom: 10px;
        padding-bottom: 30px;
        align-items: center;
    }

    .row p {
        margin: 0;
        font-size: 20px;
        cursor: pointer;
    }
}