// Use CSS class to isolate component
.nav-login-container {
    display: grid;
    grid-template-rows: 1fr min-content;

    p {
        font-size: 1.4rem;
        text-align: center;
        white-space: nowrap;
        color: var(--colour-BLACK);
    }

    .nav-login-image-container {
        padding: 2rem 0;
        border-radius: 5px;
        background-color: var(--colour-BLUE_LIGHT_2);
        border: 2px solid var(--colour-BLUE_LIGHT_3);
        display: grid;
        justify-items: center;
        align-items: center;

        img {
            max-width: 98%;
            cursor: pointer;
        }
    }

    .nav-login-user-box {
        width: 98%;
        background: var(--colour-MAIN_BLUE);
        border: solid 2px black;
        border-radius: 15px;
        overflow: hidden;

        >div {
            display: flex;
            justify-items: center;
            align-items: center;
        }

        .nav-login-classname {
            padding-top: 3px;
            font-size: 0.8em;
        }

        .nav-login-left {
            width: 75%;
            padding-left: 1rem;
        }

        .nav-login-right {
            width: 25%
        }
    }

    .nav-login-course-wrap {
        padding: 0.5rem;
    }

    /* Minimum aspect ratio */
    @media (min-aspect-ratio: 8/4) and (max-width: 800px) {
        .nav-login-image-container img {
            max-width: 75%;
        }
    }

    @media (min-aspect-ratio: 8/4) and (max-height: 800px) {
        .nav-login-image-container img {
            max-width: 75%;
        }
    }
}