.assessment-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1.5fr 1.5fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "scores-card bad-habits-card details-card";
  // grid-column-gap: 1em;


  .modal-container {


    .modal-content{
    }

    .modal-footer {
    }
  }
}

@media screen and (max-width: 800px) {
  .assessment-container {
    grid-template-areas:
      "details-card"
      "bad-habits-card"
      "scores-card";
    grid-template-columns: 1fr;
    row-gap: 40px;
    margin: 50px;
    padding: 40px;
    overflow: scroll;
    height: 90vh;
  }
}