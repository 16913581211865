.nav-layout-container {
  height: 100%;
}

.nav-layout-go-back-btn {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 3vw;
  height: 3vw;
  opacity: 0.5;
  transition: all .2s;
}

.nav-layout-go-back-btn:hover {
  width: 5vw;
  height: 5vw;
  opacity: 1;
}
