.nav-button-container {
  display: grid;
  grid-template-rows: 1fr min-content;
  cursor: pointer;
}

.nav-button-image-container {
  padding: 1rem 1rem;
  border-radius: 5px;
  background-color: var(--colour-BLUE_LIGHT_2);
  border: 2px solid var(--colour-BLUE_LIGHT_3);
  display: grid;
  justify-items: center;
  align-items: center;
}

.nav-button-title {
  font-size: 1.8rem;
  text-align: center;
  white-space: nowrap;
  color: var(--colour-BLACK);
}

/* Minimum aspect ratio */
@media (min-aspect-ratio: 8/4) and (max-width: 800px) {
  .nav-button-image-container svg {
    max-width: 75%;
  }
}
/* 
@media (min-aspect-ratio: 8/4) and (max-width: 1000px) {
  .nav-button-image-container svg {
    max-width: 65%;
  }
}

@media (min-aspect-ratio: 8/4) and (max-width: 800px) {
  .nav-button-image-container svg {
    max-width: 55%;
  }
}

@media (min-aspect-ratio: 8/4) and (max-width: 600px) {
  .nav-button-image-container svg {
    max-width: 50%;
  }
}
 */
@media (min-aspect-ratio: 8/4) and (max-height: 800px) {
    .nav-button-image-container svg {
      max-width: 75%;
    }
  }
/*   
  @media (min-aspect-ratio: 8/4) and (max-height: 600px) {
    .nav-button-image-container svg {
      max-width: 65%;
    }
  }
  
  @media (min-aspect-ratio: 8/4) and (max-height: 400px) {
    .nav-button-image-container svg {
      max-width: 100%;
    }
  }
  
  @media (min-aspect-ratio: 8/4) and (max-height: 200px) {
    .nav-button-image-container svg {
      max-width: 50%;
    }
  } */