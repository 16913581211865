.home-screen-image-container {
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
}

.home-screen-image {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    /* max-width: 100vh; */
    object-fit: cover;
    object-position: top left;
}

.main-main-content-container {
    padding: 0;
}