.marty-batt-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 40%;
    align-items: end;
    row-gap: 2px;
    padding-bottom: .8rem;
}

.marty-batt-percentage {
    font-size: 1.4rem;
}