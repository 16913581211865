@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner-container{
  display: grid;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
}

.loading-spinner {
  --border-colour: #f3f3f3;
  --border-top-colour: #383636;
}

.loading-spinner {
  width: 80%;
  height: 80%;
  border: 10px solid var(--border-colour); 
  border-top: 10px solid var(--border-top-colour); 
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  transform: translate(-50%, -50%);
}