.button-disconnect-container {
  display: grid;
  grid-template-areas:
    "marty-name marty-name marty-image"
    "marty-battery marty-signal marty-image"
    "disconnect-image disconnect-image marty-image";

  grid-template-columns: 0.3fr 0.3fr 0.4fr;
  overflow: hidden;
  padding: 0.5rem;
  padding-left: 1rem;
}

.button-disconnect-title {
  font-size: 1.8rem;
  grid-area: marty-name;
  white-space: nowrap;
}

.button-disconnect-battery-container {
  grid-area: marty-battery;
  /* padding: 1rem; */
  height: 100%;
  align-self: end;
}

.button-disconnect-signal-container {
  position: relative;
  grid-area: marty-signal;
  padding: 0.5rem;
}

.button-disconnect-disconnect-btn-container {
  grid-area: disconnect-image;
  cursor: pointer;
}

.button-disconnect-marty-container {
  grid-area: marty-image;
  align-self: end;
  transform: translateY(10px) translateX(5px);
}

.button-disconnect-signal-na {
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
}
