.main-container {
  height: 100%;
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main-navigation-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.main-main-content-container {
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 1rem;
  position: relative;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main-main-content-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-main-content-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.beta-sign-icon-container {
  display: grid;
  align-items: center;
  justify-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 8vw;
  height: 4vw;
  margin: 1rem;
  border-radius: 1rem;
  opacity: .7;
  cursor: pointer;
}

.beta-sign-icon-container:hover {
  opacity: 1;
}