// Use CSS class to isolate component
.course-select-container {
    width: 100%;

    select {
        width: 100%;
        height: 32px;
    }
    > div {
        height: 32px;
        color: var(--colour-PALE_WHITE);
    }

    .course-select-divider {
        background: gray;
        font-size: 1pt;
    }
}