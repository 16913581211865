.bug-report-modal-container {
  display: grid;
  grid-template-areas: "bug-info" "bug-form" "bug-buttons";
  grid-template-columns: 1fr;
  row-gap: 2rem;
  column-gap: 2rem;
  max-width: 90vw;
  max-height: 90vh;
}

.bug-report-modal-info {
  grid-area: bug-info;
  width: 50%;
  justify-self: center;
  text-align: center;
  color: var(--colour-GREY);
}

.bug-report-modal-form {
  grid-area: bug-form;
}

.bug-report-modal-text-input {
    width: 100%;
    padding: 1rem;
    font-size: 2rem;
    border: 1px solid var(--colour-MAIN_BLUE);
    border-radius: 5px;
    outline: none;
    font-size: 1.6rem;
    resize: vertical;
    max-height: 50vh;
}

.bug-report-modal-text-input:focus {
    outline: none;
}

.bug-report-modal-buttons {
  grid-area: bug-buttons;
  display: grid;
  grid-auto-flow: column;
  column-gap: 2rem;
}
