.simple-button-component {
    background-color: var(--colour-MAIN_BLUE);
    color: var(--colour-WHITE);
    text-align: center;
    padding: 1rem 0;
    font-family: "BigNoodleTitling Regular";
    font-size: 2rem;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 4px;
    display: grid;
    align-items: center;
}


.simple-button-component-disabled {
    background-color: var(--colour-GREY_4) !important;
    color: var(--colour-GREY_3) !important;
    cursor: not-allowed;
}