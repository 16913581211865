.categoryContainer {
    padding: 10px 30px;
    max-height: 70%;
    overflow-y: scroll !important;
    overflow-x: hidden !important;

    .title {
        padding-top: 1rem;
        padding-bottom: 2rem;
        clear: both;
    }

    .paragraph {
        width: 70%;
        float: left;
    }

    .image {
        width: 100px;
        float: right;
        vertical-align: top;
    }
}