.progress-bar-container {
  height: min-content;
  width: 100%;
  background-color: #e0e0de;
  box-shadow: inset 1px 1px 19px 1px rgba(0, 0, 0, 50%);
  border-radius: 50px;
  overflow: hidden;

  .filler {
    height: 100%;
    border-radius: inherit;
    text-align: right;
    transition: width 1s ease-in-out;
    background-color: greenyellow;
  }

  .label {
    padding: 5px;
    padding-left: 10px;
    color: white;
    font-weight: bold;
    font-size: 14px;
  }
}